import { FETCH_CUSTOMIZATIONS_QUERY } from '~/gql/customizations/fetchCustomizations'
import {
  CustomizationField,
  CustomizationFieldSetupOptions,
  type Customizations
} from '~/types/customization'

interface State {
  customizations: Customizations | null
}

export const useCustomizationStore = defineStore('customization', {
  state: (): State => ({
    customizations: null
  }),
  getters: {
    getCustomizationByField(
      state: State
    ): (field: CustomizationField) => string {
      return field => state.customizations![field]
    },
    isRecruitmentAvailable: (state: State) => {
      return (
        state.customizations![CustomizationField.IS_RECRUITMENT_AVAILABLE] ===
        CustomizationFieldSetupOptions.TRUE
      )
    },
    isTrialPeriodAvailable: (state: State) => {
      return (
        state.customizations![CustomizationField.HAS_TRIAL_PERIOD_DATA] ===
        CustomizationFieldSetupOptions.TRUE
      )
    }
  },
  actions: {
    async loadCustomizations() {
      const app = useNuxtApp()

      const customizationObjects = (
        await app.$apiGqlClient.query({
          query: FETCH_CUSTOMIZATIONS_QUERY
        })
      ).data.getCustomizations as { field: CustomizationField; value: string }[]

      this.customizations = customizationObjects.reduce(
        (acc, { field, value }) => {
          acc[field] = value
          return acc
        },
        {} as Customizations
      )
    },
    setCustomizations(customizations: NonNullable<State['customizations']>) {
      this.customizations = { ...this.customizations, ...customizations }
    }
  }
})
