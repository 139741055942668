import type { User } from './user'

export enum DataSource {
  ADP = 'adp',
  ASHBY = 'ashby',
  BAMBOOHR = 'bamboohr',
  CEGEDIM = 'cegedim',
  CEGID = 'cegid',
  CEGID_RHPLACE = 'cegid_rhplace',
  DEEL = 'deel',
  EOLIA = 'eolia',
  FLATCHR = 'flatchr',
  GREENHOUSE = 'greenhouse',
  HIBOB = 'hibob',
  ICIMS = 'icims',
  JOBAFFINITY = 'jobaffinity',
  LAYAN = 'layan',
  LEVER = 'lever',
  LUCCA = 'lucca',
  NIBELIS = 'nibelis',
  OCTIME = 'octime',
  PAYFIT = 'payfit',
  PEOPLESPHERES = 'peoplespheres',
  PERSONIO = 'personio',
  RECRUITEE = 'recruitee',
  SILAE = 'silae',
  TALEEZ = 'taleez',
  TEAMTAILOR = 'teamtailor',
  WELCOMEKIT = 'welcomekit',
  WERECRUIT = 'werecruit',
  WORKABLE = 'workable',
  WORKMOTION = 'workmotion',
  DSN = 'dsn',
  CUSTOM = 'custom'
}

export enum SourceConnectionType {
  API = 'API',
  SFTP = 'SFTP',
  FILE = 'FILE',
  CRAWLER = 'CRAWLER',
  SHAREPOINT = 'SHAREPOINT'
}

export enum SourceType {
  HRIS = 'HRIS',
  ATS = 'ATS',
  PAYROLL = 'PAYROLL',
  CUSTOM = 'CUSTOM'
}

export enum SourceAccessType {
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER'
}

export interface SourcePermission {
  user: User
  sourceAccessType: SourceAccessType
}

interface Uploader {
  id: string
  email: string
}

export interface SourceFile {
  id: string
  filename: string
  size: number
  uploader?: Uploader
  month: string
  uploadedAt: string
  syncedAt: string
  entity: string | null
}

export interface Source {
  id: string
  dataSource: DataSource
  extractedAt: string
  connectionType: SourceConnectionType
  accountName: string | null
  sourcePermissions: SourcePermission[]
  adminSourcePermissions: SourcePermission[]
  sourceFiles: SourceFile[]
}
